@import "./variable";
//选择字体大小
@mixin ft_size() {
  [data-theme="theme1"] & {
    font-size: $ft-size-theme1;
  }
  [data-theme="theme2"] & {
    font-size: $ft-size-theme2;
  }
  [data-theme="theme3"] & {
    font-size: $ft-size-theme3;
  }
}
//选择背景主题色
@mixin bg_ft_clr(){
  [data-theme="theme1"] & {
    background-color: $bg-clr-theme1;
    color: $ft-clr-theme1;
    font-size: $ft-size-theme1;
  }
  [data-theme="theme2"] & {
    background-color: $bg-clr-theme2;
    color: $ft-clr-theme2;
    font-size: $ft-size-theme2;
  }
  [data-theme="theme3"] & {
    background-color: $bg-clr-theme3;
    color: $ft-clr-theme2;
    font-size: $ft-size-theme3;
  }
}

                   //实际操作的
//按钮背景色、字体颜色
@mixin btn_opr{
  [data-theme="theme1"] & {
    background-color: $btn-opr-bg-clr-theme1;
    color: $btn-opr-ft-clr-theme1;
    border-color:$btn-opr-bg-clr-theme1;
    font-size: $ft-size-theme1;
  }
  [data-theme="theme2"] & {
    background-color: $btn-opr-bg-clr-theme2;
    color: $btn-opr-ft-clr-theme2;
    border-color:$btn-opr-bg-clr-theme2;
    font-size: $ft-size-theme2;
  }
  [data-theme="theme3"] & {
    background-color: $btn-opr-bg-clr-theme3;
    color: $btn-opr-ft-clr-theme3;
    border-color:$btn-opr-ft-clr-theme3;
    font-size: $ft-size-theme3;
  }
}

                 //无实际操作的
//按钮背景色、字体颜色
@mixin btn_no(){
  [data-theme="theme1"] & {
    background-color: $btn-no-bg-clr-theme1;
    color: $btn-no-ft-clr-theme1;
    border-color:$btn-no-bdr-clr-theme1;
    font-size: $ft-size-theme1;

  }
  [data-theme="theme2"] & {
    background-color: $btn-no-bg-clr-theme2;
    color: $btn-no-ft-clr-theme2;
    border-color:$btn-no-bdr-clr-theme2;
    font-size: $ft-size-theme2;
  }
  [data-theme="theme3"] & {
    background-color: $btn-no-bg-clr-theme3;
    color: $btn-no-ft-clr-theme3;
    border-color:$btn-no-bdr-clr-theme3;
    font-size: $ft-size-theme3;
  }
}



