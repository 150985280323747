//全局样式
html,
body {
    font-size: 12px;
}

.wraper {
   width: 100%;
   height: 100%;
}
